<template>
  <div>
    <table-list
      class="tableList"
      :title="tableTitle"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :btns="btns(this)"
      :options="{ singleCurrent: true }"
      :columns="columns(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
import { personalDetailList, personalDetailExport } from '@/api/examination'
import TableList from '@/components/TableList'
import to from 'await-to'
//表格标题
const tableTitle = '测评记录'
//表格按钮
const btns = _this => [
  {
    label: '导出',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '姓名',
    prop: 'studentName',
    defaultValue: _this.$route.query.name || '',
  },
  {
    label: '测评名称',
    prop: 'evalName',
  },
  {
    label: '手机号',
    prop: 'mobile',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '测评人姓名',
  },
  {
    prop: 'evalName',
    label: '测评名称',
  },
  {
    prop: 'mobile',
    label: '手机号',
  },
  {
    prop: 'score',
    label: '测评得分',
    align: 'right',
  },
  {
    prop: 'timeConsuming',
    label: '测评耗时（秒）',
    align: 'right',
  },
  {
    prop: 'startTime',
    label: '开始测评时间',
  },
  {
    prop: 'endTime',
    label: '结束测评时间',
  },
  {
    prop: 'platform',
    label: '使用设备',
  },
  {
    prop: 'ipAddress',
    label: 'IP地址',
  },
]
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      searchForm,
      columns,
      btns,
      loading: false,
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
        queryPlatform: 0,
      },
      queryParams: {},
    }
  },
  created() {
    if (this.$route.query.name) {
      this.queryParams.studentName = this.$route.query.name
    }
    this.personalDetailListData()
  },
  methods: {
    async personalDetailListData() {
      this.loading = true
      const { current, size, queryPlatform } = this.pager
      const [res, err] = await to(
        personalDetailList({ current, size, queryPlatform, ...this.queryParams }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.records
      this.pager.total = res.data.total
    },
    async handleExport() {
      const { current, size, queryPlatform } = this.pager
      const [res, err] = await to(
        personalDetailExport(
          { current, size, queryPlatform, ...this.queryParams },
          { responseType: 'blob' },
        ),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '个人测评详情.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.personalDetailListData()
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.personalDetailListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.personalDetailListData()
    },
  },
}
</script>

<style scoped></style>
